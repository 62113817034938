import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MdxLayout from 'components/mdx-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <MdxLayout title="Usage" subline="Applying Backbreeze classes in your project.">
    {children}
  </MdxLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "using-the-backbreeze-classes"
    }}>{`Using the Backbreeze classes`}</h2>
    <p>{`Backbreeze provides what is often called utility classes or atomic CSS. That means that it provides classes where each class applies one CSS property and one value for that property. To achieve complex styles what you need to do is combine classes together:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="c-white p-4 bg-black">
  <h3 class="fw-bold mb">
    My title
  </h3>
  <p class="o-0.5">
    Subtitle goes here
  </p>
</div>
`}</code></pre>
    <h2 {...{
      "id": "responsive-and-mobile-first"
    }}>{`Responsive and mobile first`}</h2>
    <p>{`Almost all Backbreeze generated classes are responsive (all can be made responsive via configuration if needed), that means they provide variants to change styles across breakpoints.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="c-white c-black@sm">
  This text will be white on mobile and black starting at the breakpoint \`sm\`
</div>
`}</code></pre>
    <p>{`All Backbreeze media queries use `}<inlineCode parentName="p">{`(min-width: ...)`}</inlineCode>{` exclusively, therefor a regular Backbreeze class style (a class without a `}<inlineCode parentName="p">{`@`}</inlineCode>{` screen size modifier) will always be applied unless overwritten by a `}<inlineCode parentName="p">{`@`}</inlineCode>{` class for a larger screen size. This approach is called mobile first. Create styles for mobile devices and modify them as needed when the screen gets wider.`}</p>
    <h2 {...{
      "id": "reset-stylesheet"
    }}>{`Reset stylesheet`}</h2>
    <p>{`It is recommended to use a reset style sheet of some kind, that can be `}<a parentName="p" {...{
        "href": "https://necolas.github.io/normalize.css/"
      }}>{`normalize.css`}</a>{` or (shameless plug ahead) `}<a parentName="p" {...{
        "href": "https://nicolas-cusan.github.io/destyle.css/"
      }}>{`destyle.css`}</a>{` which is well suited to for a utility approach to styling as it removes all browser default styles, this way classes are only `}<em parentName="p">{`additive`}</em>{` and do not have to be used to remove styles.`}</p>
    <h2 {...{
      "id": "class-name-structure"
    }}>{`Class name structure`}</h2>
    <p>{`Backbreeze class names are generated using the following pattern:`}</p>
    <pre><code parentName="pre" {...{}}>{`.[-][<NAMESPACE>-]<PROPERTY NAME>-<VALUE>[:<PSEUDO>][@<BREAKPOINT>]
`}</code></pre>
    <p>{`Pattern break down:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`[-]`}</inlineCode>{` Classes with negative values start with a `}<inlineCode parentName="li">{`-`}</inlineCode>{` (dash)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[<NAMESPACE>-]`}</inlineCode>{` Namespace to distinguish Backbreeze classes from others, it is set via `}<a parentName="li" {...{
          "href": "/configuration/#bb-namespace"
        }}><inlineCode parentName="a">{`$bb-namespace`}</inlineCode></a>{`. There is no namespace set by default`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<PROPERTY NAME>`}</inlineCode>{` Name that describes the property the class is setting. More frequently used properties will have shorter names e.g. a single letter, more infrequently used properties have longer names. In general class names try to be short but not to clever to make guessing a class name easy. e.g. `}<inlineCode parentName="li">{`ta`}</inlineCode>{` (for `}<inlineCode parentName="li">{`text-align`}</inlineCode>{`)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<VALUE>`}</inlineCode>{` Property value set via the key in the values variable for this utility e.g. `}<inlineCode parentName="li">{`center`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`[:<PSEUDO>]`}</inlineCode>{` Activation of the property on this pseudo selector e.g. `}<inlineCode parentName="li">{`:hover`}</inlineCode>{`, `}<inlineCode parentName="li">{`:focus`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`[@<BREAKPOINT>]`}</inlineCode>{` Breakpoint on which to apply this class e.g. `}<inlineCode parentName="li">{`@sm`}</inlineCode>{` (from `}<inlineCode parentName="li">{`sm`}</inlineCode>{` screens upwards, set via `}<a parentName="li" {...{
          "href": "/configuration/#bb-bps"
        }}><inlineCode parentName="a">{`$bb-bps`}</inlineCode></a>{`)`}</li>
    </ul>
    <p>{`Example (without namespace)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="p-20 p-40@sm -mb-30">
  <h2 class="fs-20 lh-1.2">
    <a class="c-primary:hover@md" href="#">
      John Smith
    </a>
  </h2>
</div>
`}</code></pre>
    <p>{`Example breakdown:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`<div>`}</inlineCode></strong><ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`p-20`}</inlineCode>{`: add a padding of `}<inlineCode parentName="li">{`20px`}</inlineCode>{` all around`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`p-40@sm`}</inlineCode>{`: change the padding to `}<inlineCode parentName="li">{`40px`}</inlineCode>{` from `}<inlineCode parentName="li">{`sm`}</inlineCode>{` screens upwards`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`-mb-30`}</inlineCode>{`: add a negative margin of `}<inlineCode parentName="li">{`30px`}</inlineCode>{` on the bottom`}<ul parentName="li">
              <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`<h2>`}</inlineCode></strong><ul parentName="li">
                  <li parentName="ul"><inlineCode parentName="li">{`fs-20`}</inlineCode>{`: set the the font size to `}<inlineCode parentName="li">{`20px`}</inlineCode></li>
                  <li parentName="ul"><inlineCode parentName="li">{`lh-1.2`}</inlineCode>{`: set the line height to `}<inlineCode parentName="li">{`1.2`}</inlineCode><ul parentName="li">
                      <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`<a>`}</inlineCode></strong><ul parentName="li">
                          <li parentName="ul"><inlineCode parentName="li">{`c-primary:hover@md`}</inlineCode>{`: change the color when hovered from `}<inlineCode parentName="li">{`md`}</inlineCode>{` screens upwards`}</li>
                        </ul></li>
                    </ul></li>
                </ul></li>
            </ul></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "using-backbreeze-variables-in-your-code"
    }}>{`Using Backbreeze variables in your code`}</h2>
    <p>{`As everything is just Sass you can use all variables / values via the regular sass mechanisms:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// Using some color in a module
.my-module {
  color: map-get($bb-color, 'primary');
}
`}</code></pre>
    <p>{`You can also use your own variables to define Backbreeze values:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$primary: #4a90e2;

// Use color in Backbreeze classes
$bb-color-values: (
  'primary': $primary,
  // ...,,
);

// Use color in a module
.my-module {
  color: $primary;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      